$purple: #43358E;

.contact-bar {
  background: $purple;
  color: #fff;
  font-size: 12px;
  padding: 8px 0;
}

.contact-bar-grid {
  display: flex;
  
  .contact-grid-item {
    display: flex;
    width: auto;
  
    &.justify-right {
      justify-content: flex-end;
    }
  
    width: 50%;
  }
}




@media screen and (max-width: 762px) {
.contact-bar-grid {
  // &.spaceBetween {
  //   justify-content: flex-start;
  // }

  // .contact-grid-item {
  //   &.justify-right {
  //     justify-content: flex-start;
  //   }

  //   width: auto;
  // }
}
}


