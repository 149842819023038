.testimonial {
  padding: 0;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .quote {
    margin-bottom: 8px;
    font-size: 35px;
    text-align: center;
    display: flex;
    justify-content: center;

    img {
      max-width: 80px;
    }
  }
}