@import './helpers.scss';
@import '../fonts/montserrat/stylesheet.css';
@import './Normalize.scss';

$purple: #43358E;
$green: #20A84A;

* {
  box-sizing: border-box;
  -moz-background-size: auto 50%;
  -webkit-background-size: auto 50%;
  -o-background-size: auto 50%;
  background-size: auto 50%;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

body {
  font-family: 'montserratregular';
  letter-spacing: 0.3px;
  font-size: 16px;

}

h1,
h2,
h3,
h4,
h5 {
  font-family: 'montserratbold';
  color: $purple;
  margin: 0;
  position: relative;

  &.underlined {
    margin-bottom: 14px;

    &:after {
      content: '';
      position: absolute;
      width: 60px;
      height: 4px;
      background: $green;
      left: 0;
    }
  }

  &.white {
    color: #fff;
  }

  &.dark {
    color: #000;
  }

  &.green {
    color: $green
  }

}

#root {
  padding-top: 250px;
}

hr {
  height: 2px;
  border: none;
  background: rgba(66, 53, 142, 0.377)
}

h1 {
  font-size: 50px
}

h2 {
  font-size: 35px;
  line-height: 45px;
  padding: 0 0 25px 0;

  &.underlined {
    padding-bottom: 20px;

    &:after {
      top: 98%;
    }
  }

  &.underlined.large {
    &:after {
      top: 80%;
    }
  }

  &.underlined.regular {
    &:after {
      top: 87%;
    }
  }
}

h3 {
  font-size: 19px;
  line-height: 24px;
  padding: 20px 0;

  &.underlined {
    margin-bottom: 9px;

    &:after {
      top: 90%;
    }
  }

  &.default {
    color: #000;
    font-size: 16px;
    padding-bottom: 0;
  }
}

p {
  line-height: 26px !important;
  margin: 0;
  padding: 0 0 20px 0;

  &.white {
    color: #fff;
  }

  &.bold {
    font-family: 'montserratbold';
  }
}

a {
  color: inherit;

  &:hover {
    color: inherit
  }
}

button {
  letter-spacing: 0.3px;
}

input[type='checkbox'] {
  cursor: pointer;
}

img {
  max-width: 100%;
  display: block;
}

dl,
ol,
ul {
  padding: 0;
  margin: 0;

  li {
    list-style: none
  }

  &.has-dots {
    padding-top: 10px;
    padding-left: 20px;
    line-height: 25px;

    li {
      list-style: disc
    }
  }

  &.mission-ul {
    min-height: 217px;
  }

  &.auto-height {
    min-height: auto;
  }

  &.form-list {
    li {
      padding: 3px 0;

    }
  }
}



.bold {
  font-family: 'montserratbold';
}

.size-medium {
  font-size: 20px
}

.ui.grid {
  margin-top: 0;
  margin-bottom: 0;

  .row {
    padding: 0
  }
}

.breadcrumb {
  padding: 0;
}

.color-green {
  color: $green
}

.width-auto {
  width: 100%
}

.sigCanvas {
  border: 1px solid #ddd !important;
  cursor: url('../../assets/icon/pen.png'), auto;
}

.spacer-90 {
  padding-top: 90px;
}

.css-6rti61,
.css-1357ed0,
.css-ghtq4v {
  background: #43358E !important;
  box-shadow: none !important;
  width: 30px !important;
  height: 30px !important;
  margin-right: 2px !important;
  margin-left: 2px !important;
}

.css-6rti61>svg,
.css-1357ed0>svg,
.css-ghtq4v>svg {
  fill: #fff !important;
  width: 18px !important;
}

.css-6rti61:hover {
  background: #43358E
}

.g-recaptcha {
  display: flex;
  justify-content: center;

  div:first-child {
    transform: translate(8px, 0px) scale(.925);
  }
}

@media screen and (max-width: 1023px) {
  #root {
    padding-top: 138px;
  }

  .size-medium {
    font-size: 18px;
  }
}

@media screen and (max-width: 768px) {

  dl,
  ol,
  ul {
    &.mission-ul {
      min-height: 260px;
    }
  }

  br.hide-on-mobile {
    display: none
  }

  .g-recaptcha {
    margin-bottom: 20px;
  }
}


@media screen and (max-width: 841px) {

  dl,
  ol,
  ul {
    &.mission-ul {
      min-height: 280px;
    }
  }
}

@media screen and (max-width: 767px) {

  dl,
  ol,
  ul {
    &.mission-ul {
      min-height: auto;
    }
  }
}



@media screen and (max-width: 414px) {
  .g-recaptcha {
    div:first-child {
      transform: translate(1px, 0px) scale(1);
    }
  }

  h1 {
    font-size: 25px;
  }

  h2 {
    font-size: 25px;
    line-height: 35px;
    padding: 10px 0 30px 0;
  }

  h3 {
    font-size: 18px;
    line-height: 24px;
    padding: 20px 0 10px 0;

    &.underlined:after {
      top: 96%;
    }
  }

}
.text-decoration_underline{
  text-decoration: underline;
}