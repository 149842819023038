$yellow: #D6B052;

.popup-overlay {
  background: rgba(0, 0, 0, 0.7);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center
}

.popup-container {
  max-height: 100vh;
  max-width: 95%;
}

.popup {
  z-index: 10;
  width: 800px;
  // padding: 60px 30px;
  box-shadow: 0px 5px 15px #70707096;
  position: relative;
  color: #43358E;
  text-align: center;
  max-width: 95%;
  margin: 0 auto;
  background-color: #fff;
  padding: 30px;

  h2 {
    font-size: 30px;
    line-height: 28px;
    padding: 0 0 20px 0;
    color: #fff;
  }
  p{
    padding-bottom: 0;
  }

  .popup-close {
    position: absolute;
    top: -15px;
    right: -15px;
    cursor: pointer;
    background-color:#20A84A;
    border-radius: 40px;
    box-shadow: 4px 4px 13px 0px rgba(0,0,0,0.75);
    -webkit-box-shadow: 4px 4px 13px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: 4px 4px 13px 0px rgba(0,0,0,0.75);
    img{
      &svg{
        fill: #fff;
      }
      
    }
  }

  .popup-confirm {
    div form {
      button {
        margin: 10px !important
      }
    }

    input {
      margin-right: 10px;
      cursor: pointer;
    }

    display: flex;
    font-size: 15px;
    align-items: center
  }
}

.age-confrim-checkbox {
  display: flex;
  align-items: center;

}

.age-gate-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 50%;
}

.age-gate-left {
  background: #fff;
  width: 40%;
}

.age-gate-right {
  padding: 130px 80px;
  color: #fff;
  font-size: 25px;
  background: #5B2A81;
  width: 60%;

  .age-gate-link {
    font-size: 16px;

    a {
      color: $yellow;
      padding-left: 3px;
    }
  }

  span {
    color: #D6B052;
  }
}


@media screen and (max-width: 1000px) {


  .popup {
    flex-direction: column
  }

  .age-gate-left,
  .age-gate-right {
    width: 100%;
  }

  .age-gate-left {
    padding: 20px;
  }

  .age-gate-right {
    padding: 20px;
  }

  .age-gate-box {
    height: 100%;
  }
}

@media screen and (max-width: 678px) {
  .popup {
    width: 90%;
    max-width: 400px;
    margin: 0 auto;
  }
}

@media screen and (max-width: 680px) {
  .popup {

    position: relative;
  }

  .age-gate-confirm {
    div form {
      margin: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;

      button {
        margin: 0 0 70px 0 !important;
      }
    }
  }

  .age-gate-right .age-gate-link {
    font-size: 12px;
  }

  .popup {
    h3 {
      font-size: 14px;
      color: $yellow;
      margin: 0;
    }
  }

  .age-gate-left {
    padding: 60px 20px;
  }

  .age-gate-right {
    font-size: 16px;
    padding: 60px 20px;

    p {
      margin-bottom: 5px !important;
    }
  }
}

.ac_mobile{
  width: 100%;
  cursor:pointer;
}
.acc_desktop{
  width: 100%;
  display: none;
  cursor:pointer;
}

@media screen and (min-width: 679px){
  .ac_mobile{
    width: 100%;
    display: none;
  }
  .acc_desktop{
    width: 100%;
    display:block;
  }
}



