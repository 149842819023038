.modal {
  display: none;
  position: fixed;
  width: 100%;
  max-width: 650px;
  top: 50%;
  left: 50%;
  padding: 40px 20px 60px 20px;
  transform: translate(-50%, -50%);
  background: #f9f9fd;
  z-index: 100;
  -webkit-box-shadow: 0 8px 6px -6px rgb(216, 216, 216);
  -moz-box-shadow: 0 8px 6px -6px rgb(216, 216, 216);
       box-shadow: 0 8px 6px -6px #4141413a;
  
  &.active {
    display: block !important;
  }

  .close {
    display: flex;
    justify-content: flex-end;
    cursor: pointer;
  }
}