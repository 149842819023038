.preview-card {
  text-align: left;
  margin-bottom: 30px;
  
  .preview-image {
    overflow:hidden;
    backface-visibility: hidden;
    position: relative;
  }

  img {
    width: 100%;
    display: block;
    transition: all 200ms ease;
    transform: scale(1.04);
    &:hover {
      transform: scale(1.2);
    }
  }

  h3 {
    min-height: 90px;
  }

  p {
    min-height: 90px;
  }
}

@media screen and (max-width: 991px){
  .preview-card {
    p {
      min-height: 63px;
    }
  }
}

@media screen and (max-width: 414px){
  .preview-card {
    
    p {
      min-height: auto;
    }
  

    h3 {
      min-height: 63px;
    }
  }
}

