$purple: #43358E;

#section-footer-nav {
  display: flex;
  justify-content: center;
  background: $purple;
  padding: 60px 0 40px 0;

  .logo {
    padding-top: 0;
    padding-bottom: 25px;
  }
}

.footer-nav {
  line-height: 25px;

  display: flex;
  justify-content: center;
  max-width: 80%;
  margin: 0 auto;
}

.footer-nav ul {
  margin: 0 0 20px 0;

  text-align: center;

  li {
    display: inline-block;
    padding: 0 13px;
    border-right: 1px solid #fff;
    color: #fff;
    font-family: 'montserratbold';
    a {
      color: #fff;
      font-size: 16px; 
  
    }

    &:first-child {
      padding-left: 0
    }

    &:last-child {
      border: none
    }
  }
}

@media screen and (max-width: 768px) {
  .footer-nav {
    display: none
  }
}

@media screen and (max-width: 414px) {
  .footer-nav ul {
    max-width: 100%;
    margin: 0 20px 20px 20px ;
    text-align: left;
  
    li {
      display: block;
      padding: 0;
      border-right: none;
      color: #fff;
      font-family: 'montserratbold';
      a {
        color: #fff;
        font-size: 16px; 
    
      }
  
      &:first-child {
        padding-left: 0
      }
  
      &:last-child {
        border: none
      }
    }
  }
}