.white {
  color: #fff;
}

.centered {
  text-align: center;
}

.x-small {
  font-size: 12px;
}

.small {
  font-size: 14px;
}

.medium {
  font-size: 20px;
}

.bold {
  font-family: 'montserratbold'
}

.no-padding {
  padding: 0;
}

.padding-small {
  padding: 0 0 7px 0;
}