.logo {
  padding: 40px 0;
  
  img {
    max-width: 728px;
  }
  
  &.centered {
    display: flex;
    justify-content: center;
  }
}

@media screen and (max-width: 1023px){
  .logo {
    img {
      max-width: 320px;
    }

    &.centered {
      margin: 0 auto;
    }
  }
}

@media screen and (max-width: 768px){
  .logo {
    img {
      max-width: 470px;
    }

    &.centered {
      margin: 0 auto;
    }
  }
}

@media screen and (max-width: 414px){
  .logo {
    img {
      max-width: 260px;
    }

    &.centered {
      margin: 0 auto;
    }
  }
}


@media screen and (max-width: 370px){
  .logo {
    img {
      max-width: 230px;
    }

    &.centered {
      margin: 0 auto;
    }
  }
}