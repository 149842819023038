.form-container {
  width: 100%;
  max-width: 610px;
  margin: 0 auto;
  padding: 0 20px;

  &.left-aligned {
    margin: 0 ;
  }

  &.no-padding {
    padding: 0;
  }
}

@media screen and (max-width: 4114px){
 
}