$green: #20A84A;

.info-card {
  display: flex;
  flex-direction: column;

  align-items: center;
  padding: 30px 13px 30px 13px;
  border: 1px solid #EDEDED;
  border-bottom: 4px solid $green;
  cursor: pointer;
  transition: box-shadow 120ms;
  min-height: 426px;

  &:hover {
    box-shadow: 0 3px 6px #dddddd;
  }

  h3 {
    text-align: center;
    min-height: 80px
  }

  p {
    text-align: center;
  }
}

@media screen and (max-width: 1199px) {
  .info-card {
    margin-bottom: 30px;
  }
}