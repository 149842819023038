$purple: #43358E;

.icon-button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 12px;
    text-align: center;
    
    span {
        display:  block;
        line-height: 20px;
    }
}

@media screen and (max-width: 762px) {
    .icon-button {
        span {
            display:  none;
            
        }
    }
}