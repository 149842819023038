.terms-list li {
  display: flex;
  justify-content: flex-start;
  p {
    width: 90%
  }
  span {
    color: #43358E;
    font-weight: 600;
    display: block;
    width: 100px !important;
  }
}