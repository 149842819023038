.icon-list {
  display: flex;
  align-items: center;
  
  li {
    padding: 0 5px;

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
    }
  }

  &.centered {
    justify-content: center
  }
}

@media screen and (max-width: 830px){
  
  .icon-list {

    &.will-stack-medium {
      display: block;
      flex-direction: column;
      align-items: flex-start;

      li {
        padding-left: 0;
        padding-bottom: 15px;
      }
    }
  }
}

@media screen and (max-width: 414px){
  .icon-list {

    &.will-stack{
      display: block;
      flex-direction: column;
      align-items: flex-start;

      li {
        padding-left: 0;
        padding-bottom: 15px;
      }
    }
  }
}