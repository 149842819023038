$purple: rgba(66, 53, 142, 0.87);

.banner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  height: 100%;

  h2 {
    padding: 0px;
    text-shadow: 0px 3px 6px #0000004D;
    &.underlined {
      padding-bottom: 10px;
    }

    &.no-shadow {
      text-shadow: none;
    }
  }

  .banner-content {
    display: flex;
    justify-content: space-between;

  }

  .banner-overlay {
    padding: 60px 0;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: $purple;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    height: 100%;

    &.no-overlay {
      background: transparent;
    }

    &.small {
      padding: 40px 0;
    }
  }

  .banner-full {
    width :100%;
  }

  .banner-left {
    position: relative;
    width: 65%;

    &.half {
      width: 49%;
      display: flex;
      justify-content: center;
      align-content: center;
      flex-direction: column;

      img {
        align-self: center
      }
    }
  }

  .banner-right {
    position: relative;
    width: 35%;

    &.half {
      width: 49%;
      display: flex;
      justify-content: center;
      align-content: center;
      flex-direction: column;

      img {
        align-self: center
      }
    }
  }

  .banner-image-small {
    width: 100%;
    max-width: 350px;
  
  }
}

@media screen and (max-width: 768px){
  .banner {
    .banner-overlay {
      flex-direction: column;
    }

    .banner-content {
      flex-direction: column-reverse;
    }
  
    .banner-left {
      position: relative;
      width: 100%;

      &.half {
        width: 100%;
      }
    }
  
    .banner-right {
      position: relative;
      width: 100%;

      &.half {
        width: 100%;
      }

      &.center-mobile {
        text-align: center;
      }
    }

    .banner-image-small {
      margin-top: 60px;
    }
  }
}



