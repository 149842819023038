.article-details {
  display: flex;
  margin-bottom: 35px;
}

@media screen and (max-width: 414px){
  .article-details {
    flex-direction: column;

    .article-detail {
      margin-bottom: 20px;
    }
  }
}