.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;
  background: #fff;
  transition: transform 200ms ease;
  -webkit-box-shadow: 0 6px 5px -5px rgba(51, 51, 51, 0.13);
  -moz-box-shadow: 0 6px 5px -5px rgba(51, 51, 51, 0.13);
       box-shadow: 0 6px 5px -5px rgba(51, 51, 51, 0.13);
}

.header-container-hidden {
  transform: translateY(-177px);
}

@media screen and (max-width: 768px){
  .header-container-hidden {
    transform: translateY(-140px);
  }
}